import api, {ApiPromise} from '../api';

export interface User {
  name: string | null;
  email: string | null;
  country_code: string;
  avatar: string | null;
  current_order_id?: string;
}

export const fetchUser = (): ApiPromise<User> => api.get('/user/me');
