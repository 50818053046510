module.exports = [{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"includeInDevelopment":false,"dsn":"https://d456659a32d34103925751ec2d71bbd1@o4504445489840128.ingest.sentry.io/4504445492985856","sampleRate":0.7},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5XTQPK3","includeInDevelopment":false,"enableWebVitalsTracking":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","lt","ru"],"defaultLanguage":"en","fallbackLanguage":"en","siteUrl":"https://fortula.app","trailingSlash":"never","i18nextOptions":{"interpolation":{"escapeValue":false}},"pages":[{"matchPath":"/404-page","languages":["en"]},{"matchPath":"/sapno-reiksme/:dream","languages":["en"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3acbc7055fb7ecdf6d0b0914f2b10c9f"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
