import axios, {AxiosResponse} from 'axios';
import {get} from './storage';

export interface ApiErrorResponseData {
  code: string;
  message: string;
  additional_data?: {
    field: any;
    message: string;
    code: string;
  }[];
}

export type ApiPromise<T> = Promise<AxiosResponse<T>>;

export const buildAuthorizationHeader = (token: string) => `Bearer ${token}`;

const api = axios.create({
  baseURL: process.env.API_URL,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  (config) => {
    const accessToken = get('access_token');

    if (accessToken !== null) {
      config.headers = {
        ...config.headers,
        Authorization: buildAuthorizationHeader(accessToken),
      };
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export default api;
