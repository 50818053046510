import React, {FunctionComponent, ReactNode, useContext, useEffect} from 'react';
import {createContext, useState} from 'react';
import {authenticate, AuthenticateRequest, register, RegisterRequest} from '../services/api/authenticationAPI';
import {Redirect, useLocation} from '@reach/router';
import {fetchUser, User} from '../services/api/userAPI';
import jwtDecode from 'jwt-decode';
import {getTime} from 'date-fns';
import {AxiosResponse} from 'axios';
import {navigateWithLanguage} from '../services/navigation';
import {get, remove, store} from '../services/storage';
import * as Sentry from "@sentry/browser";

interface Context {
  accessToken: string | null;
  authenticateUser: (authenticateData: AuthenticateRequest, language: string, redirectUrl?: string) => void;
  registerUser: (registerData: RegisterRequest, redirectUrl?: string) => void;
  user: User | null;
  isAuthenticatingInBackground: boolean;
  logoutUser: () => void;
}

const ACCESS_TOKEN_KEY = 'access_token';

export const storeAccessToken = (accessToken: string) => store(ACCESS_TOKEN_KEY, accessToken);
export const retrieveAccessToken = () => get(ACCESS_TOKEN_KEY);
export const deleteAccessToken = () => remove(ACCESS_TOKEN_KEY);

export const AuthenticationContext = createContext<Context>({
  accessToken: null,
  authenticateUser: () => {},
  registerUser: () => {},
  user: null,
  isAuthenticatingInBackground: false,
  logoutUser: () => {},
});

export const AuthenticationProvider = ({children}: {children: ReactNode}) => {
  const storedAccessToken = retrieveAccessToken();

  const [accessToken, setAccessToken] = useState<string | null>(storedAccessToken);
  const [user, setUser] = useState<User | null>(null);
  const [isAuthenticatingInBackground, setIsAuthenticatingInBackground] = useState<boolean>(storedAccessToken !== null);

  useEffect(() => {
    if (accessToken !== null) {
      try {
        const tokenData = jwtDecode<{exp: number}>(accessToken);

        if ((tokenData.exp - 3600) * 1000 < getTime(new Date())) {
          logoutUser();
          return;
        }
      } catch (e) {
        logoutUser();
        return;
      }

      const fetchAndStoreUserData = async () => {
        try {
          const {data} = await fetchUser();
          setUser(data);
        } catch (e: any) {
          if (e.response) {
            const errorData = e.response as AxiosResponse;

            if (errorData.status === 401) {
              logoutUser();
              return;
            }
          }

          Sentry.captureException(e);
        }

        setIsAuthenticatingInBackground(false);
      };

      fetchAndStoreUserData();
    }
  }, []);

  const authenticateUser = async (authenticateData: AuthenticateRequest, language: string, redirectUrl?: string) => {
    //todo: return last fortune id and redirect to it (proceed with regular flow, either payment or details based on status returned). Return data ?
    const {data} = await authenticate(authenticateData);

    setAccessToken(data.access_token);
    storeAccessToken(data.access_token);

    setUser(data.user);

    if (data.user.current_order_id) {
      redirectUrl = `/dashboard/fortunes/${data.user.current_order_id}`;
    }

    if (redirectUrl !== undefined) {
      await navigateWithLanguage(language, redirectUrl, {replace: true});
      return;
    }
  };

  const registerUser = async (registerData: RegisterRequest, redirectUrl?: string) => {
    const {data} = await register(registerData);

    setAccessToken(data.access_token);
    storeAccessToken(data.access_token);

    setUser(data.user);

    if (redirectUrl !== undefined) {
      await navigateWithLanguage(registerData.language, redirectUrl, {replace: true});
      return;
    }
  };

  const logoutUser = () => {
    deleteAccessToken();
    setUser(null);
    setAccessToken(null);
    setIsAuthenticatingInBackground(false);

    //todo: fix FB
    if (window.FB) {
      window.FB.logout();
    }
  };

  const value = {accessToken, authenticateUser, user, isAuthenticatingInBackground, registerUser, logoutUser};

  return <AuthenticationContext.Provider value={value}>{children}</AuthenticationContext.Provider>;
};

export const useAuthentication = () => useContext(AuthenticationContext);

export const RequireAuth: FunctionComponent<{children: ReactNode}> = ({children}): any => {
  const {accessToken, isAuthenticatingInBackground} = useAuthentication();
  const location = useLocation();

  if (isAuthenticatingInBackground) {
    return <div>Loading</div>;
  }

  if (accessToken === null) {
    return <Redirect noThrow to='/app/authentication' state={{from: location}} />;
  }

  return children;
};
