export const store = (key: string, value: string | number | object) => {
  if (typeof window !== 'undefined' && window.localStorage) {
    if (typeof value !== 'object') {
      window.localStorage.setItem(key, value as string);
    } else {
      window.localStorage.setItem(key, JSON.stringify(value));
    }
  }
};

export const get = (key: string): string | null => {
  if (typeof window !== 'undefined' && window.localStorage) {
    return window.localStorage.getItem(key);
  }

  return null;
};

export const remove = (key: string) => {
  if (typeof window !== 'undefined' && window.localStorage) {
    window.localStorage.removeItem(key);
  }
};

export const getData = <T>(key: string): T | null => {
  const storedItem = get(key);

  if (storedItem !== null) {
    return JSON.parse(storedItem);
  }

  return null;
};
