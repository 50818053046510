import api, {ApiPromise} from '../api';
import {User} from './userAPI';

export interface AuthenticateRequest {
  type: 'facebook' | 'google' | 'email';
  access_token?: string;
  email?: string;
}

export interface AuthenticateResponse {
  access_token: string;
  user: User;
}

export interface RegisterRequest extends AuthenticateRequest {
  name: string;
  birthdate: string;
  gender: 'male' | 'female';
  country_code: string;
  language: string;
}

export const authenticate = (data: AuthenticateRequest): ApiPromise<AuthenticateResponse> =>
  api.post('/authenticate', data);

export const register = (data: RegisterRequest): ApiPromise<AuthenticateResponse> => api.post('/register', data);
